import styled from "styled-components";

const Divider = styled.div`
    border: 0;
    height: 1px;
    background-color: black;
    opacity: 20%;
    width: 80%;
    align-self: center;
`;

export default Divider;