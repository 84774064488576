import styled from "styled-components";

const Section = styled.section`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
`;

const TitledSection = ({title, children} : React.PropsWithChildren<{title: string}>) => {
    return (
        <Section>
            <h3>{title}</h3>
            {children}
        </Section>
    )
}

export default TitledSection;