import styled from "styled-components";

const LinkIconSvg = styled.svg`
    --base-width: 35px;
    --base-height: 35px;
    
    fill: black;
    width: var(--base-width);
    height: var(--base-height);
    transition: all 0.2s ease-in-out;
    padding: 8px;
    border-radius: 10px;
    
    &:hover {
        fill: white;
        background-color: black;
    }

    &:active {
        width: calc(var(--base-width) - 6px);
        height: calc(var(--base-height) - 6px);
        margin: 3px;
    }
`;

export default LinkIconSvg;