import styled from "styled-components";
import TitledSection from "./shared/TiltedSection";
import getIconPath from "../../getIconPath";

const SkillsList = styled.div`
    display: flex;
    flex-flow: row wrap;
    gap: 1rem;
`;

const Skill = styled.div`
    padding: 1rem;
    border-radius: 20px;
    transition: all 0.2s ease-in-out;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    box-shadow: ${props => props.theme.shadow};

    @media (max-width: ${props => props.theme.screen.mobile}) {
        & {
            flex-direction: column;
            padding: 0.7rem 1.3rem;
        }
    }
`;

const SkillIcon = styled.img`
    width: 40px;
    height: 40px;
`;

const SkillTitle = styled.div`
    font-weight: bold;
`;

const SkillsSection = ({skills}: {skills: string[]}) => {
    return (
        <TitledSection title={"#skills"}>
            <SkillsList>
                {skills.map(s => {
                    return (
                        <Skill>
                            <SkillIcon src={getIconPath(s)}/>
                            <SkillTitle>{s}</SkillTitle>
                        </Skill>
                    )
                })}
            </SkillsList>
        </TitledSection>
    )
}

export default SkillsSection;