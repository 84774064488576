import React, {useState} from "react";
import styled from "styled-components";
import userImg from "../../assets/userPicture.png";

const Container = styled.div`
    position: relative;
    width: 100%;
    height: 200px;
    background-image: url("static/media/background.png");
    background-size: cover;   
    margin-bottom: 30px;

    @media (max-width: ${props => props.theme.screen.mobile}) {
        & {
            margin-bottom: 120px;
        }
    }
`;

const BannerImg = styled.img`
    width: 100%;
`;

const UserImg = styled.img`
    position: absolute;
    top: 50%;
    left: 20%;
    transform: translateX(-50%);
    width: 210px;
    height: 200px;
    border-radius: 140px;
    padding: 4px;
    background-color: white;
    box-shadow: ${props => props.theme.shadow};

    @media (max-width: ${props => props.theme.screen.mobile}) {
        & {
            left: 50%;
            justify-content: center;
        }
    }
`;

const BannerSection = () => {
    return (
        <Container>
            <UserImg src={userImg} alt="user's img"/>
        </Container>
    )
}

export default BannerSection;