import styled from "styled-components";

const TooltipContainer = styled.div`
    position: relative;
`;

const StyledTooltip = styled.div`
    visibility: hidden;
    position: absolute;
    top: 105%;
    left: 50%;
    transform: translateX(-50%); // center the tooltip
    text-align: center;
    padding: 0.5rem;
    background-color: white;
    box-shadow: ${props => props.theme.shadow};
    z-index: 100;
    font-weight: bold;
    border-radius: 5px;
    white-space: nowrap;

    ${TooltipContainer}:hover & {
        visibility: visible;
    }
`;

interface TooltipProps extends React.PropsWithChildren {
    text: string
}

const Tooltip = ({text, children} : TooltipProps) => {
    return (
        <TooltipContainer>
            <StyledTooltip>
                {text}
            </StyledTooltip>
            {children}
        </TooltipContainer>
    )
}

export default Tooltip;