import styled from "styled-components";
import TiltedSection from "./shared/TiltedSection";

const StyledSummary = styled.div`
    word-spacing: 1px;
    line-height: 1.5;
    font-size: 1.1rem;
`;

const Summary = () => {
    return (
        <TiltedSection title={"#about"}>
            <StyledSummary>
                <b>Full-Stack Software Engineer</b> with <b>five years</b> of experience and a <b>Master's Degree</b> in Software Engineering.
                Expert in <b>architecting</b>, <b>building</b>, and <b>launching</b> software applications from scratch,
                as well as <b>maintaining</b> complex existing systems
            </StyledSummary>
        </TiltedSection>
    )
}

export default Summary;