import styled from "styled-components";

const AppContainer = styled.div`
    position: absolute;
    width: 40%;
    left: 30%;
    padding-bottom: 2rem;
    
    @media (max-width: ${props => props.theme.screen.mobile}) {
        & {
            left: 2.5%;
            width: 95%;
        }
    }
`;

export default AppContainer;
