import styled from "styled-components";
import Typewriter from 'typewriter-effect';

const HeaderContainer = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
`;

const Hello = styled.p`
    font-size: 1.5rem;
`;

const Name = styled.h1`
`;

const Summary = styled.p`
`;

const Header = () => {
    return (
        <HeaderContainer>
            <Hello>
                Hello there!
            </Hello>
            <Name>
                <Typewriter
                    options={{
                        strings: ['I am Bohdan Breus'],
                        autoStart: true,
                        loop: true,
                    }}
                />
            </Name>
        </HeaderContainer>
    )
}

export default Header;