import React from 'react';
import ReactGA4 from "react-ga4";
import AppContainer from "./components/tsx/AppContainer";
import {ThemeProvider} from "styled-components";
import theme from "./theme";
import Divider from "./components/tsx/shared/Divider";
import SkillsSection from "./components/tsx/SkillsSection";
import Header from "./components/tsx/Header";
import LinksSection from "./components/tsx/LinksSection";
import Summary from "./components/tsx/Summary";
import MainContent from "./components/tsx/MainContent";
import BannerSection from "./components/tsx/BannerSection";

const mySkills = [
    'Java',
    'JavaScript',
    'TypeScript',
    'HTML',
    'CSS',
    'SQL',
    'Spring',
    'React',
    'REST API',
    'gRPC',
    'Hibernate',
    'Redis',
    'RabbitMQ',
    'Kafka',
    'Elasticsearch',
    // 'Microservices',
    // 'Unit/Integration testing',
    'Git',
    'OpenAI',
    'Docker',
    'Kubernetes',
    'Terraform',
    'AWS',
    'Jira'
]

ReactGA4.initialize('G-WSFXVB647W');

const App = () => {
  return (
      <ThemeProvider theme={theme}>
        <AppContainer>
            <MainContent>
                <BannerSection/>
                <LinksSection/>
                <Header/>
                <Divider/>
                <Summary/>
                <Divider/>
                <SkillsSection skills={mySkills}/>
            </MainContent>
        </AppContainer>
      </ThemeProvider>
  );
}

export default App;
