import styled from "styled-components";

const MainContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1rem;

    background-color: white;

    z-index: 10;

    //padding: 2rem 1rem;

    //box-shadow: ${props => props.theme.shadow};
`;

export default MainContent;